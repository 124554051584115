<template>
  <v-row class="pa-5">
    <v-col cols="12">
      <div class="mb-5 font-weight-bold" style="cursor: pointer; width: 100px">
        <v-btn
          @click="
            $router.push({
              name: 'Absent',
              query: {
                tab: 1,
                grade: $route.query.grade,
                class: $route.query.class
              }
            })
          "
          depressed
        >
          <v-icon>mdi-arrow-left</v-icon>
          {{ $t("absent.absent_per_subjects") }}</v-btn
        >
      </div>
    </v-col>
    <v-col cols="12" class="d-flex align-end">
      <v-radio-group
        v-model="roleType"
        row
        hide-details
        @change="isTeacher ? showDialogAddManual() : getLessonAbsent()"
      >
        <v-radio :label="$t('routes.student')" value="student" />
        <v-radio
          :label="isTeacher ? $t('absent.teacher_me') : $t('routes.teacher')"
          value="teacher"
        />
      </v-radio-group>
      <v-spacer />
      <v-text-field
        v-model="search"
        :label="$t('app.search')"
        :class="$vuetify.breakpoint.xsOnly && 'mt-2'"
        outlined
        dense
        hide-details
        clearable
        style="max-width: 250px"
        prepend-inner-icon="mdi-magnify"
        @keydown="searchHandler()"
        @click:clear="searchHandler()"
      />
    </v-col>
    <v-col cols="12">
      <v-divider class="my-3" />
    </v-col>
    <v-col cols="12" sm="7">
      <v-row>
        <v-col cols="4">
          {{ $t("academic.subjects_title") }}
        </v-col>
        <v-col cols="8"> : {{ lessonSchedule.subjectsName || "-" }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          {{ $t("absent.clock") }}
        </v-col>
        <v-col cols="5"> : {{ lessonSchedule.time || "-" }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          {{ $t("master_data.table.class") }}
        </v-col>
        <v-col cols="5"> : {{ lessonSchedule.className || "-" }}</v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="5" class="d-flex justify-md-end">
      <v-select
        v-model="idDate"
        :items="dateItems"
        :loading="loadingDate"
        :label="$t('absencePerSubject.select_date')"
        :disabled="!allowChangeDate"
        item-text="dateFormat"
        item-value="date"
        outlined
        dense
        class="select-200 mt-2"
        append-icon="mdi-calendar-range"
        @change="getLessonAbsent()"
      />
    </v-col>
    <v-col cols="12">
      <div v-if="multipleId.length > 0" class="d-flex justify-end">
        <!-- Button Add Manual Absent -->
        <v-tooltip v-if="showButtonAdd" left max-width="200">
          <template #activator="{ on }">
            <div v-on="on">
              <v-btn
                dark
                class="primary mx-1"
                icon
                small
                @click="showDialogAddManual()"
              >
                <v-icon size="20">mdi-plus-circle-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ $t("absent.added_manual") }}</span>
        </v-tooltip>
        <!-- Button Unapproved -->
        <v-tooltip v-if="showBtnUnapproved" left max-width="200">
          <template #activator="{ on }">
            <div v-on="on">
              <v-btn
                class="grey mx-1"
                dark
                icon
                small
                @click="showDialogApprovedAbsent(true)"
              >
                <v-icon size="20">mdi-check</v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ $t("absent.unapproved") }}</span>
        </v-tooltip>
        <!-- Button Approved -->
        <v-tooltip v-if="showBtnApproved" left max-width="200">
          <template #activator="{ on }">
            <div v-on="on">
              <v-btn
                class="indigo mx-1"
                dark
                icon
                small
                @click="showDialogApprovedAbsent(false)"
              >
                <v-icon size="20">mdi-check</v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ $t("absent.approved") }}</span>
        </v-tooltip>
        <!-- Button Delete -->
        <v-tooltip v-if="showButtonDelete" left max-width="200">
          <template #activator="{ on }">
            <div v-on="on">
              <v-btn
                dark
                class="error mx-1"
                icon
                small
                @click="showDialogDeleteAbsent()"
              >
                <v-icon size="20">mdi-delete-forever</v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ $t("absent.delete_manual") }}</span>
        </v-tooltip>
      </div>
    </v-col>
    <v-col cols="12" class="mt-3">
      <v-data-table
        v-model="multipleId"
        :headers="tableHeaders"
        :items="dataStudent"
        :items-per-page="dataStudent.length"
        :loading="loadingTable"
        item-key="id"
        disable-pagination
        hide-default-footer
        show-select
        class="elevation-0"
      >
        <template #item.status_text="{ item }">
          <div v-if="!item.approved && item.status === '1'">
            <div>{{ item.status_text }}</div>
            <div>({{ $t("absent.absent_has_not_been_approved") }})</div>
          </div>
          <div v-else>{{ item.status_text }}</div>
        </template>
        <template #item.action="{ item }">
          <v-row class="d-flex align-center justify-center">
            <!-- Button Add Manual Absent -->
            <v-tooltip left max-width="200">
              <template #activator="{ on }">
                <div v-on="on">
                  <v-btn
                    :dark="multipleId.length === 0 ? true : false"
                    :disabled="multipleId.length > 0 || item.status !== '0'"
                    class="primary mr-2"
                    icon
                    small
                    @click="showDialogAddManual(item)"
                  >
                    <v-icon size="20">mdi-plus-circle-outline</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>{{ $t("absent.added_manual") }}</span>
            </v-tooltip>
            <!-- Button Approved / Unapproved -->
            <v-tooltip left max-width="200">
              <template #activator="{ on }">
                <div v-on="on">
                  <v-btn
                    :dark="multipleId.length === 0 ? true : false"
                    :disabled="multipleId.length > 0 || item.status === '0'"
                    :class="item.approved ? 'grey' : 'indigo'"
                    icon
                    small
                    @click="showDialogApprovedAbsent(item)"
                  >
                    <v-icon size="20">mdi-check</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>
                {{
                  item.approved
                    ? $t("absent.unapproved")
                    : $t("absent.approved")
                }}
              </span>
            </v-tooltip>
            <!-- Button Delete -->
            <v-tooltip left max-width="200">
              <template #activator="{ on }">
                <div v-on="on">
                  <v-btn
                    :dark="multipleId.length === 0 ? true : false"
                    :disabled="multipleId.length > 0 || item.status === '0'"
                    class="error ml-2"
                    icon
                    small
                    @click="showDialogDeleteAbsent(item)"
                  >
                    <v-icon size="20">mdi-delete-forever</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>{{ $t("absent.delete_manual") }}</span>
            </v-tooltip>
          </v-row>
        </template>
      </v-data-table>
    </v-col>

    <v-dialog
      v-model="dialogAdd"
      transition="scale-transition"
      persistent
      max-width="400px"
    >
      <v-card outlined>
        <v-card-title class="title">
          {{ $t("absent.added_manual") }}
        </v-card-title>
        <v-divider />
        <v-card-text class="mt-2">
          <v-form ref="form">
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="modelStatus"
                  :items="statusItems"
                  :rules="[v => !!v || $t('app.required')]"
                  :loading="loadingStatusItems"
                  label="Status"
                  item-value="id"
                  item-text="label"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" class="mt-2">
                <v-textarea
                  v-model="infoAddAbsent"
                  :label="$t('journal.table.information')"
                  outlined
                  rows="3"
                  hide-details
                  dense
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-end>
            <v-btn
              outlined
              small
              dark
              class="mr-2 gradient-error"
              @click="closeDialogAddAbsent"
            >
              {{ $t("app.cancel") }}
            </v-btn>
            <v-btn
              :loading="loadingAddManual"
              outlined
              class="gradient-primary"
              dark
              small
              @click="saveAddManualAbsent"
            >
              {{ $t("app.save") }}
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ModalConfirm
      :title="$t('absent.delete_absent_in')"
      :close="() => (this.confirmDelete = false)"
      :loading="loadingDeleteAbsent"
      :isOpen="confirmDelete"
      :save="deleteAbsences"
    />
    <ModalConfirm
      :title="
        isApproved
          ? $t('absent.unapproved_absent')
          : $t('absent.approved_absent')
      "
      :close="() => (this.modalApproved = false)"
      :loading="loadingApproved"
      :isOpen="modalApproved"
      :content="
        isApproved
          ? $t('absent.msg.unapproved_absent')
          : $t('absent.msg.approved_absent')
      "
      :save="saveApprovedAbsent"
    />
  </v-row>
</template>

<script>
import {
  dropDownDate,
  lessonAbsent,
  lessonAbsentStatus,
  lessonAbsentEdit,
  approvedAbsent,
  signAbsent
} from "@/api/admin/absent/absent";
import i18n from "@/i18n";
import moment from "moment/moment";

let typingTimer;
const doneTypingInterval = 750;

export default {
  components: {
    ModalConfirm: () => import("@/components/ModalConfirm")
  },
  created() {
    this.getDropDownDate();
    this.getlessonAbsentStatus();
  },
  watch: {
    multipleId(value) {
      this.showButtonAdd = value.some(v => v.status === "0");
      this.showButtonDelete = value.some(v => v.status !== "0");
      this.showBtnApproved = value.some(v => !v.approved && v.status === "1");
      this.showBtnUnapproved = value.some(v => v.approved && v.status === "1");
    }
  },
  data() {
    return {
      tableHeaders: [
        {
          text: i18n.t("app.name"),
          align: "left",
          value: "name",
          width: 180
        },
        {
          text: "UID",
          value: "uid",
          width: 130
        },
        {
          text: i18n.t("bk.table.status"),
          value: "status_text",
          width: 130
        },
        {
          text: i18n.t("journal.table.information"),
          value: "note",
          width: 150
        },
        {
          text: i18n.t("absent.table.action"),
          sortable: false,
          value: "action",
          align: "center",
          width: 120
        }
      ],
      lessonSchedule: {
        className: null,
        subjectsName: null,
        time: null
      },
      infoAddAbsent: "",
      dialogAdd: false,
      modalApproved: false,
      loadingApproved: false,
      isApproved: false,
      confirmDelete: false,
      loadingDeleteAbsent: false,
      loadingTable: false,
      loadingStatusItems: false,
      loadingAddManual: false,
      modelStatus: null,
      dataStudent: [],
      multipleId: [],
      statusItems: [],
      roleType: "student",
      idDate: null,
      dateItems: [],
      loadingDate: false,
      search: null,
      idData: [],
      showButtonAdd: false,
      showButtonDelete: false,
      showBtnUnapproved: false,
      showBtnApproved: false,
      isTeacher: this.$store.getters.g_role_type === "TEACHER",
      isStudent: this.$store.getters.g_role_type === "STUDENT",
      allowChangeDate: false
    };
  },
  methods: {
    showDialogAddManual(item) {
      if (!this.isTeacher || item) {
        if (this.multipleId.length) this.idData = this.multipleId;
        else this.idData = [item.id];
      }
      this.dialogAdd = true;
    },
    showDialogApprovedAbsent(item) {
      if (this.multipleId.length) this.idData = this.multipleId;
      else this.idData = [item.id];
      this.modalApproved = true;
      this.isApproved = this.multipleId.length > 0 ? item : item.approved;
    },
    showDialogDeleteAbsent(item) {
      if (this.multipleId.length) this.idData = this.multipleId;
      else this.idData = item.id;
      this.confirmDelete = true;
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    async saveAddManualAbsent() {
      if (this.$refs.form.validate()) {
        this.loadingAddManual = true;
        const idData =
          this.multipleId.length > 0
            ? this.multipleId.filter(v => v.status === "0").map(v => v.id)
            : this.idData;
        try {
          let response;
          if (this.isTeacher && this.roleType !== "student") {
            response = await signAbsent({
              lesson_schedule: this.$route.params.id,
              status: this.modelStatus,
              note: this.infoAddAbsent
            });
          } else {
            response = await lessonAbsentEdit({
              id: idData,
              status: this.modelStatus,
              note: this.infoAddAbsent
            });
          }
          if (response.data.code) {
            this.snackBar(true, this.$i18n.t("absent.response.success"));
          } else this.snackBar(false, this.$i18n.t("absent.response.failed"));
        } catch (error) {
          console.error("saveAddManualAbsent()\n", error);
        } finally {
          this.loadingAddManual = false;
          this.closeDialogAddAbsent();
        }
      }
    },
    async deleteAbsences() {
      this.loadingDeleteAbsent = true;
      const idData =
        this.multipleId.length > 0
          ? this.multipleId.filter(v => v.status !== "0").map(v => v.id)
          : [this.idData];
      try {
        const response = await lessonAbsentEdit({
          id: idData,
          status: "0",
          note: ""
        });
        if (response.data.code) {
          this.snackBar(true, this.$i18n.t("absent.response.success_delete"));
        } else
          this.snackBar(false, this.$i18n.t("absent.response.failed_delete"));
        this.closeDialogAddAbsent();
      } catch (error) {
        console.error("deleteAbsences()\n", error);
      } finally {
        this.loadingDeleteAbsent = false;
      }
    },
    async saveApprovedAbsent() {
      this.loadingApproved = true;
      const keyMsg = this.isApproved ? "unapproved_absent" : "approved_absent";
      const idData =
        this.multipleId.length > 0
          ? this.multipleId
              .filter(v => v.approved === this.isApproved && v.status === "1")
              .map(v => v.id)
          : [this.idData];
      try {
        const response = await approvedAbsent({
          id: idData,
          approved: !this.isApproved
        });
        if (response.data.code) {
          this.snackBar(true, this.$i18n.t(`absent.msg.success.${keyMsg}`));
        } else
          this.snackBar(false, this.$i18n.t("absent.response.failed_approve"));
        this.closeDialogAddAbsent();
      } catch (error) {
        console.error("saveApprovedAbsent()\n", error);
      } finally {
        this.loadingApproved = false;
      }
    },
    closeDialogAddAbsent() {
      if (this.dialogAdd) this.$refs.form.reset();
      this.dialogAdd = false;
      this.infoAddAbsent = "";
      this.modelStatus = null;
      this.idData = [];
      this.confirmDelete = false;
      this.modalApproved = false;
      this.isApproved = false;
      if (this.isTeacher) this.roleType = "student";
      this.getLessonAbsent();
    },
    searchHandler() {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.getLessonAbsent();
      }, doneTypingInterval);
    },
    async getDropDownDate() {
      const { id } = this.$route.params;
      try {
        this.loadingDate = true;
        if (id) {
          const response = await dropDownDate({
            lesson_schedule: parseInt(id)
          });
          if (response.data.code) {
            this.dateItems = response.data.data
              .filter(item => moment(item.date).isBefore(moment()))
              .map(item => ({
                ...item,
                dateFormat: moment(item.date).format("DD MMM YYYY")
              }));
            if (this.dateItems.length) {
              this.idDate = this.dateItems[0].date;
              this.getLessonAbsent();
            }
          }
        }
      } catch (error) {
        console.error("getDropDownDate()\n", error);
      } finally {
        this.loadingDate = false;
      }
    },
    async getlessonAbsentStatus() {
      try {
        this.loadingStatusItems = true;
        const response = await lessonAbsentStatus();
        if (response.data.code) {
          this.statusItems = response.data.data.filter(item => item.id !== "0");
        }
      } catch (error) {
        console.error("getlessonAbsentStatus()\n", error);
      } finally {
        this.loadingStatusItems = false;
      }
    },
    async getLessonAbsent() {
      const { id } = this.$route.params;
      this.multipleId = [];
      try {
        this.loadingTable = true;
        if (id) {
          const response = await lessonAbsent({
            lesson_schedule: parseInt(id),
            date: this.idDate,
            role: this.roleType.toUpperCase(),
            search: this.search
          });
          if (response.data.code === 1 && response.data.data !== null) {
            const { lesson_schedules, data, access_edit } = response.data.data;
            this.lessonSchedule.className = lesson_schedules.classname;
            this.lessonSchedule.subjectsName = lesson_schedules.lesson_text;
            this.lessonSchedule.time = `${lesson_schedules.start_time.substr(
              0,
              5
            )} - ${lesson_schedules.end_time.substr(0, 5)}`;
            this.dataStudent = data;
            this.allowChangeDate = access_edit;
          } else {
            if (response.data.code === 0)
              this.snackBar(false, response.data.message);
            this.dataStudent = [];
          }
        }
      } catch (error) {
        console.error("getLessonAbsent()\n", error);
      } finally {
        this.loadingTable = false;
      }
    }
  }
};
</script>
