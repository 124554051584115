import request from "@/utils/request";

export function getListAbsent(data, isStudent) {
  return request({
    method: "post",
    url: isStudent ? "student/list-absensi" : "employee/list-absensi",
    data: data
  });
}

export function getTypeAbsent() {
  return request({
    method: "get",
    url: "absen/list-izin"
  });
}

export function getRecap(data) {
  return request({
    method: "post",
    url: "applicant/porspective_applicants",
    data: data
  });
}

export function updateAbsent(data) {
  return request({
    method: "post",
    url: "absen/update-absensi",
    data: data
  });
}

export function deleteAbsent(data) {
  return request({
    method: "post",
    url: "absen/delete-absensi",
    data: data
  });
}

export function absentException(data) {
  return request({
    method: "post",
    url: "absen/list-in",
    data: data
  });
}

export function reportAbsent(data) {
  return request({
    method: "post",
    url: "absen/report-absensi",
    data: data
  });
}

export function dropDownDate(data) {
  return request({
    method: "post",
    url: "lesson-absence/dd-date",
    data: data
  });
}

export function lessonAbsent(data) {
  return request({
    method: "post",
    url: "lesson-absence/get",
    data: data
  });
}

export function lessonAbsentStatus() {
  return request({
    method: "post",
    url: "lesson-absence/status"
  });
}

export function lessonAbsentEdit(data) {
  return request({
    method: "post",
    url: "lesson-absence/edit",
    data: data
  });
}

export function approvedAbsent(data) {
  return request({
    method: "post",
    url: "lesson-absence/approve",
    data: data
  });
}

export function signAbsent(data) {
  return request({
    method: "post",
    url: "lesson-absence/sign",
    data: data
  });
}
